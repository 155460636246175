const STATIC_IMAGES = {
  brandAnchor: '/images/brandAnchor.png',
  brandDMC: '/images/brandDMC.png',
  brandKreinik: '/images/brandKreinik.png',
  brandSullivans: '/images/brandSullivans.png',
  customize: '/images/customize.png',
  track: '/images/track.png',
  videoPoster: '/images/videoPoster.png',
};

export default STATIC_IMAGES;
