import React from 'react';
import tw from 'twin.macro';

import { ButtonCssProps, cssForButtonProps } from './Button';
import ResourceLink, { ResourceLinkProps } from './ResourceLink';

type ButtonLinkProps = ResourceLinkProps &
  ButtonCssProps & {
    disabled?: boolean;
  };

const ButtonLink = ({
  as, // omitted due to typescript error
  children,
  color,
  disabled,
  hue,
  shape,
  size,
  variant,
  ...rest
}: ButtonLinkProps) => (
  <ResourceLink
    css={[
      disabled && tw`opacity-50 pointer-events-none`,
      cssForButtonProps({
        color,
        hue,
        shape,
        size,
        variant,
      }),
    ]}
    {...rest}
    tw="inline-block text-center"
  >
    {children}
  </ResourceLink>
);

export default ButtonLink;
